<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/exam-management" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.examManagement.title") }}
        </router-link>
        <router-link to="/module/exam-management/exam/category" class="btn btn-primary align-self-center ms-3">
            {{ $t("pages.module.examManagement.exam.category.title") }}
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr024.svg" />
            </span>
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.module.examManagement.exam.title')"
        :subTitle="$t('pages.module.examManagement.exam.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleExamFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            {{ resolveDatum(record.translations, $root.defaultLanguage.id, 'language_id').title }}
        </template>
        <template v-slot:examDate="{ row: record }">
            {{ $moment(record.exam_date).format("DD.MM.YYYY") }}
        </template>
        <template v-slot:withoutSession="{ row: record }">
            <span :class="record.without_session ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.without_session ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.examManagement.exam.session.user.title')" placement="top">
                    <router-link :to="'/module/exam-management/exam/session/user?examID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.module.examManagement.exam.result.send.title')" placement="top" v-if="record.exam_resulted && record.exam_result_date && !record.without_session">
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"  v-on:click="sendResult(record)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen002.svg"/>
                        </span>
                    </a>
                </el-tooltip>
                <el-tooltip :content="$t('pages.module.examManagement.exam.result.title')" placement="top" v-if="record.exam_resulted || record.without_session">
                    <router-link :to="'/module/exam-management/exam/result?examID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <el-tooltip :content="$t('pages.module.examManagement.exam.finalize.title')" placement="top" v-else-if="record.sessions_count > 0 && !record.without_session">
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"  v-on:click="finalizeExam(record)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/files/fil008.svg"/>
                        </span>
                    </a>
                </el-tooltip>
                <router-link :to="'/module/exam-management/exam/save/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                     <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg" />
                     </span>
                </router-link>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_finalize_exam" ref="finalizeExamModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ finalizeExamForm.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitFinalizeExam()" :model="finalizeExamForm.data" ref="finalizeExamForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.finalize.cols.finalizeMethod') }}</label>
                                <el-form-item prop="finalize_method" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="finalizeExamForm.data.finalize_method" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                        <el-option :value="1" :label="$t('pages.module.examManagement.exam.finalize.finalizeMethods.justEnded')"></el-option>
                                        <el-option :value="2" :label="$t('pages.module.examManagement.exam.finalize.finalizeMethods.willChoose')"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7" v-if="finalizeExamForm.data.finalize_method == 2">
                                <label class="fs-6 fw-bold mb-2">
                                    {{ $t('pages.module.examManagement.exam.finalize.cols.session') }}
                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.examManagement.exam.finalize.informationBoxes.session')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="sessions">
                                    <el-select v-model="finalizeExamForm.data.sessions" class="w-100" :placeholder="$t('common.chooseSelect')" multiple clearable>
                                        <el-option v-for="(session, sessionIndex) in finalizeExamSessions" :key="sessionIndex" :value="session.id" :label="$moment(session.session_date + ' ' + session.session_time).format('DD.MM.YYYY HH:mm')"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="finalizeExamForm.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="finalizeExamForm.loading">
                            <span v-if="!finalizeExamForm.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="finalizeExamForm.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_send_exam_result" ref="sendExamResultModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ sendExamResultForm.title }}</h2>
                    <div id="kt_modal_send_exam_result_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitSendExamResult()" :model="sendExamResultForm.data" ref="sendExamResultForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_send_exam_result_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.examManagement.exam.result.send.cols.sendType') }}</label>
                                <el-form-item prop="send_type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="sendExamResultForm.data.send_type" class="w-100" :placeholder="$t('common.chooseSelect')" multiple>
                                        <el-option v-for="(sendType, sendTypeIndex) in sendExamResultForm.sendTypes" :key="sendTypeIndex" :value="sendType" :label="$t('pages.module.examManagement.exam.result.send.sendType.'+ sendType)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row">
                                <div class="alert alert-warning d-flex align-items-center p-5 mt-12 mb-0">
                                    <span class="svg-icon svg-icon-2hx svg-icon-warning me-4">
                                        <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                                    </span>
                                    <div class="d-flex flex-column">
                                        <h4 class="mb-1 text-warning">{{ $t('messages.warning') }}</h4>
                                        <span>{{ $t('pages.module.examManagement.exam.result.send.sendResultWarning')  }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="sendExamResultForm.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="sendExamResultForm.loading">
                            <span v-if="!sendExamResultForm.loading" class="indicator-label">{{ $t("btn.send") }}</span>
                            <span v-if="sendExamResultForm.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>

</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.examDate"),
                    scopedSlots: {customRender: "examDate"}
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.examDuration"),
                    key: "exam_duration"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.questionCount"),
                    key: "question_count"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.questionRelationCount"),
                    key: "questions_count"
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.sessionCount"),
                    key: "sessions_count",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.totalUserSessionCount"),
                    key: "total_user_sessions_count",
                    class: ""
                },
                {
                    name: this.$t("pages.module.examManagement.exam.cols.withoutSession"),
                    scopedSlots: {customRender: "withoutSession"},
                    class: ""
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"},
                    class: ""
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            selectedRowKeys: [],
            finalizeExamForm: {
                title: '',
                loading: false,
                data: {},
                sessions: [],
                responseMessages: {
                    all_exam_sessions_must_be_completed_in_order_to_be_finalized: "allExamSessionsCompletedInOrderFinalized",
                    this_exam_cannot_be_result_as_has_no_session: "examHasNotResultSessions"
                }
            },
            sendExamResultForm: {
                sendTypes: ['mail'], //'mail'
                title: '',
                loading: false,
                data: {},
                responseMessages: {}
            },

        }
    },
    computed: {
        table() {
            return this.$store.state.module.exam.table;
        },
        finalizeExamSessions(){
            return this.finalizeExamForm.sessions.filter((session) => session.is_session_ended);
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.examManagement.exam.title"), [this.$t("menu.moduleManagement"), this.$t("pages.module.examManagement.title")]);

        this.$store.dispatch('module/exam/get', {
            page: {},
            filterData: this.$root.getFilterWithUrl()
        });
    },
    methods:{
        deleteRecord(id){
            this.$store.dispatch("module/exam/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.$router.push({
                        path: "/module/exam-management/exam/save"
                    });
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/exam/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/exam/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        finalizeExam(record) {
            this.finalizeExamForm.data = {
                exam_id: record.id,
                sessions: []
            };
            this.finalizeExamForm.title = this.$t("pages.module.examManagement.exam.finalize.title");

            this.axios.get(this.endpoints['module_exam'] + '/' + record.id).then(response => {
                if(response.data.status){
                    this.finalizeExamForm.sessions = response.data.data.sessions;
                }
            });

            this.showModal(this.$refs.finalizeExamModal);
        },
        onSubmitFinalizeExam() {
            this.$refs.finalizeExamForm.validate((valid) => {
                if (valid) {
                   this.finalizeExamForm.loading = true;

                    let formData = this.cloneData(this.finalizeExamForm.data);
                    if(formData.finalize_method == 1) {
                        formData.sessions = null;
                    }

                    this.axios.post(this.endpoints['module_exam_result'], formData).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.finalizeExamModal);
                        }, () => {
                            this.finalizeExamForm.loading = false;
                        });
                    }).catch(error => {
                        if(this.finalizeExamForm.responseMessages[error.response.data.message]) {
                            error.response.data.message = this.$t('pages.module.examManagement.exam.finalize.responseMessages.' + this.finalizeExamForm.responseMessages[error.response.data.message]);
                        }

                        this.onResponseFailure(error.response.data, () => {
                            this.finalizeExamForm.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        sendResult(record) {
            this.sendExamResultForm.data = {
                send_type: ['mail'],
                exam_id: record.id
            };
            this.sendExamResultForm.title = this.$t("pages.module.examManagement.exam.result.send.title");
            this.showModal(this.$refs.sendExamResultModal);
        },
        onSubmitSendExamResult() {
            this.$refs.sendExamResultForm.validate((valid) => {
                if (valid) {
                    this.sendExamResultForm.loading = true;

                    this.axios.post((this.endpoints['module_exam_result'] + '/' + this.sendExamResultForm.data.exam_id + '/send'), this.sendExamResultForm.data).then(response => {
                        this.onResponse(response.data, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.sendExamResultModal);
                        }, () => {
                            this.sendExamResultForm.loading = false;
                        });
                    }).catch(error => {
                        this.onResponseFailure(error.response.data, () => {
                            this.sendExamResultForm.loading = false;
                        });
                    });
                } else {
                    return false;
                }
            });
        },
    }
}
</script>

<style scoped>

</style>